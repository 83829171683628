import { MainSubStructure } from '@/utils';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import Editor from './modal/editor';
import AdvertisingManagementModel from './model';

const AdvertisingManagement = () => {
  const store = useMemo(() => new AdvertisingManagementModel(), []);
  const { mainStore, editorStore } = store;
  return (
    <div className="h-screen">
      <MainSubStructure store={mainStore} />
      <Editor store={editorStore} />
    </div>
  );
};

export default observer(AdvertisingManagement);
