import { MainSubStructureModel, type PaginationData, RenderByPermission, request } from '@/utils';
import { Button, Image, Modal, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import EditorModel from './modal/editorModel';

export default class AdvertisingManagementModel {
  @observable public editorStore: EditorModel = new EditorModel(this);
  constructor() {
    this.mainStore.onQuery();
  }
  @observable public mainStore: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增广告',
        type: 'primary',
        permissionId: '2',
        handleClick: () => {
          this.editorStore.onShow('add');
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'operation',
          name: '操作',
          width: 140,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div className="operation">
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.editorStore.onShow('edit', row);
                    }}
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.delete(row.id);
                    }}
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '广告名称',
        },
        {
          key: 'terminal',
          name: '终端',
          formatter: ({ row }) => {
            const { terminal } = row;
            if (!terminal) {
              return '';
            }
            return terminal === 1 ? '主播端' : '雇主端';
          },
        },
        {
          key: 'url',
          name: '广告图片',
          width: 250,
          formatter: ({ row }) =>
            row.url ? (
              <div className="flex items-center h-full">
                <Image
                  src={row.url}
                  style={{
                    width: '180px',
                    height: '100px',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ) : (
              ''
            ),
        },
        {
          key: 'linkType',
          name: '跳转地址',
          formatter: ({ row }) => {
            const { linkType, text, pageAddress, mealName } = row;
            if (linkType === 0) {
              return '不跳转';
            } else if (linkType === 1) {
              return pageAddress;
            } else if (linkType === 2) {
              return mealName;
            } else if (linkType === 3) {
              return '自定义内容';
            } else {
              return '';
            }
          },
        },
        {
          key: 'status',
          name: '广告状态',
          formatter: ({ row }) => {
            const { status } = row;
            return status === 0 ? '待上线' : status === 1 ? '已上线' : '';
          },
        },
        {
          key: 'effectiveTime',
          name: '生效时间',
          formatter: ({ row }) => {
            const { tackEffectStartTime, tackEffectEndTime } = row;

            const startTimeFormatted = tackEffectStartTime
              ? dayjs(tackEffectStartTime).format('YYYY-MM-DD HH:mm:ss')
              : '';
            const endTimeFormatted = tackEffectEndTime ? dayjs(tackEffectEndTime).format('YYYY-MM-DD HH:mm:ss') : '';

            if (!startTimeFormatted && !endTimeFormatted) {
              return '';
            }

            return (
              <div className="h-full leading-[20px] flex flex-col justify-center">
                <p>{startTimeFormatted}</p>
                <span className="ml-3">-</span>
                <p>{endTimeFormatted}</p>
              </div>
            );
          },
        },
        {
          key: 'sort',
          name: '排序',
        },
        {
          key: 'memo',
          name: '备注',
        },
        {
          name: '更新人',
          key: 'updatePerson',
          width: 200,
          formatter: ({ row }) => {
            if (!row.updateBy) {
              return '';
            }
            return (
              <div className="h-[100%] leading-[20px] flex flex-col justify-center">
                <p>
                  用户昵称：<span>{row.updateByName}</span>
                </p>
                <p>
                  ID：<span>{row.updateBy}</span>
                </p>
                <p>
                  手机号：<span>{row.updateByMobile}</span>
                </p>
              </div>
            );
          },
        },
        {
          key: 'updateTime',
          name: '更新时间',
          formatter: ({ row }) => {
            const { updateTime } = row;
            if (Array.isArray(updateTime) && updateTime.length === 6) {
              const [year, month, day, hour, minute, second] = updateTime;
              const date = new Date(year, month - 1, day, hour, minute, second);
              return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
            }
            return '';
          },
        },
        {
          key: 'createTime',
          name: '创建时间',
          formatter: ({ row }) => {
            const { createTime } = row;
            if (Array.isArray(createTime) && createTime.length === 6) {
              const [year, month, day, hour, minute, second] = createTime;
              const date = new Date(year, month - 1, day, hour, minute, second);
              return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
            }
            return '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      rowHeight: 110,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    api: {
      onQuery: (params) => {
        const { ...rest } = params;
        const postParams = {
          ...rest,
        };
        return request<PaginationData<any>>({
          url: '/pbb/platform/sys/advertisement/info/list',
          method: 'POST',
          data: {
            ...postParams,
          },
        });
      },
    },
  });

  @action public delete = (id: any) => {
    Modal.confirm({
      title: '提示',
      content: '是否确认删除该广告吗?',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({
          url: '/pbb/platform/sys/advertisement/delete',
          method: 'post',
          data: { id },
        });
        message.success('删除成功');
        this.mainStore.onQuery();
      },
    });
  };
}
