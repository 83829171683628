import RichText from '@/compnents/richText';
import { EpUpload } from '@/utils';
import compressImgOrVideo from '@/utils/compress';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Upload,
  message,
} from 'antd';
import type { RcFile } from 'antd/es/upload';
import { observer } from 'mobx-react';
import { useState } from 'react';
import type EditorModal from './editorModel';
const Editor = ({ store }: { store: EditorModal }) => {
  const { type, visible, setRef, onClose, onSubmit, showInput, setEffectTime, effectTIme, showType, terminal } = store;
  return (
    <Modal
      title={type === 'add' ? '新增广告' : '编辑广告'}
      centered={true}
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      width={600}
      onOk={onSubmit}
    >
      <Form
        ref={setRef}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        initialValues={{
          linkType: 0,
          tackEffectType: 1,
        }}
      >
        <div className="m-2">基础信息</div>
        <Form.Item
          label="广告名称"
          name="name"
          rules={[{ required: true, message: '请输入广告名称' }]}
        >
          <Input
            placeholder="请输入广告名称"
            maxLength={20}
          />
        </Form.Item>
        <Form.Item
          label="终端"
          name="terminal"
          rules={[{ required: true, message: '请选择终端' }]}
        >
          <Select
            labelInValue={true}
            options={terminal}
            placeholder="请选择终端"
            onChange={(e) => {
              store.getMealList(e.value);
              console.log(e.value);
            }}
            disabled={type === 'edit'}
          />
        </Form.Item>

        <ImageUpload
          label="广告图片"
          name="url"
          message="广告图片"
          size={6}
        />

        <Form.Item
          label="跳转地址"
          name="linkType"
          rules={[{ required: true, message: '请选择跳转地址' }]}
        >
          <Radio.Group
            onChange={(e) => {
              showInput(e.target.value);
            }}
            options={[
              {
                label: '不跳转',
                value: 0,
              },

              {
                label: '小程序套餐',
                value: 2,
              },
              {
                label: '指定页面',
                value: 1,
              },
              {
                label: '自定义',
                value: 3,
              },
            ]}
          />
        </Form.Item>

        {/* 小程序页面 */}
        {showType === 1 && (
          <Form.Item
            wrapperCol={{ offset: 5, span: 16 }}
            name="pageAddress"
            rules={[{ required: true, message: '请选择跳转小程序页面' }]}
          >
            <Input placeholder="请输入跳转小程序页面" />
          </Form.Item>
        )}
        {/* 套餐页面 */}
        {showType === 2 && (
          <Form.Item
            wrapperCol={{ offset: 5, span: 16 }}
            name="mealId"
            rules={[{ required: true, message: '请选择跳转套餐' }]}
          >
            <Select
              labelInValue={true}
              placeholder="请选择跳转套餐"
              options={store.projectList}
            />
          </Form.Item>
        )}
        {/* 自定义 */}
        {showType === 3 && (
          <Form.Item
            wrapperCol={{ offset: 5, span: 16 }}
            name="text"
            rules={[{ required: true, message: '请输入跳转地址' }]}
          >
            {/* <Input placeholder="请输入跳转地址" />
             */}
            <RichText />
          </Form.Item>
        )}

        <div className="m-2">规则设置</div>
        <Form.Item
          name="tackEffectType"
          label="生效时间"
          rules={[{ required: true, message: '请选择广告生效时间' }]}
        >
          <Radio.Group
            onChange={(e) => {
              setEffectTime(e.target.value);
            }}
            options={[
              {
                label: '不限',
                value: 0,
              },
              {
                label: '有效期',
                value: 1,
              },
            ]}
          />
        </Form.Item>
        {effectTIme && (
          <Form.Item
            wrapperCol={{ offset: 5, span: 16 }}
            name="time"
            rules={[{ required: true, message: '请选择广告生效时间' }]}
          >
            <DatePicker.RangePicker showTime={true} />
          </Form.Item>
        )}
        {/* <div className="flex ml-10">
          <Form.Item
            label="频次"
            name="frequency"
            labelCol={{ span: 9 }}
            rules={[{ required: true, message: '请输入广告频次' }]}
          >
            <Input placeholder="请输入广告频次" />
          </Form.Item>
          <div className="mt-2 ml-2">天内更新一次</div>
        </div> */}
        <Form.Item
          label="排序"
          name="sort"
          rules={[{ required: true, message: '请输入广告排序' }]}
        >
          <InputNumber
            min={0}
            precision={0}
            step={1}
            className="w-92"
            placeholder="请输入排序"
          />
        </Form.Item>
        <Form.Item
          label="备注"
          name="memo"
        >
          <Input.TextArea
            showCount={true}
            autoSize={{ minRows: 3 }}
            placeholder="请输入内容"
            maxLength={200}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ImageUpload = (props: {
  readonly name: string;
  readonly label: string;
  readonly message: string;
  readonly size: number;
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Form.Item
      noStyle={true}
      shouldUpdate={(prevValues, nextValues) => {
        return prevValues[props.name] !== nextValues[props.name];
      }}
    >
      {({ getFieldValue, setFieldValue }) => {
        const imageUrl = getFieldValue(props.name);
        const customRequest = async (options: any) => {
          console.log(options.file, 'options.file');

          setLoading(true);
          const unit = options.file.name.split(',');
          const file: any = await compressImgOrVideo(options.file);
          if (file === false) {
            return;
          }
          const url = await EpUpload.singleUpload({
            attachmentType: 1,
            module: 'opus',
            file: file,
          });
          setFieldValue(props.name, url);
          setLoading(false);
        };

        const beforeUpload = (file: RcFile) => {
          if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            message.error('图片格式只能为jpg、jpeg、png');
            return false;
          }
          // 限制文件大小，例如限制为 2MB
          const isLtSzie = file.size / 1024 / 1024 < (props.size || 6);
          if (!isLtSzie) {
            message.error(`图片需要小于${props.size}MB!`);
            return false;
          }
          return true;
        };

        return (
          <Form.Item
            label={props.label}
            name={props.name}
            getValueFromEvent={(e) => imageUrl}
            validateTrigger={false}
            required={true}
          >
            <div className={'w-[180px] h-[100px] bg-[rgba(223,223,223,0.2)]'}>
              <Upload
                listType="text"
                showUploadList={false}
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                className="w-[180px] h-[100px]"
                accept=".jpg,.jpeg,.png,.webp"
              >
                <div className="w-[180px] h-[100px] ">
                  {imageUrl ? (
                    <Image
                      src={imageUrl}
                      preview={false}
                      style={{
                        width: '180px',
                        height: '100px',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <button
                      style={{
                        border: 0,
                        background: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '180px',
                        height: '100px',
                      }}
                      type="button"
                    >
                      {loading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div className="mt-2">上传</div>
                    </button>
                  )}
                </div>
              </Upload>
            </div>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default observer(Editor);
